<!--
 * @Author: lbh
 * @Date: 2022-06-21 15:19:50
 * @LastEditors: lbh
 * @LastEditTime: 2024-09-10 14:48:42
 * @Description: file content
-->
<template>
  <div class="swiper-box px-swiper-box" :data-tab-color="configs.tabColor">
    <el-carousel
      class="swiper"
      trigger="click"
      indicator-position="horizontal"
      :autoplay="hasEdit ? false : configs.autoplay"
      :loop="configs.loop"
      :interval="configs.interval * 1000"
      :initial-index="configs.initialIndex || 0"
    >
      <el-carousel-item v-for="(item, index) in configs.items" :key="index">
        <div
          v-if="item.type == 2 && !showPhone"
          class="item-box"
          :class="`edit_${Xindex}_item_${index}`"
          @click="clickItem('item', index)"
        >
          <img class="bg-img" :src="item.bgImg" :alt="item.bgAlt" />
          <div class="center-body">
            <div class="text-body">
              <h1
                class="title"
                v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
              ></h1>
              <h2
                class="desc"
                v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc)"
              ></h2>
              <!-- 按鈕 -->
              <div class="btns">
                <div
                  class="btn"
                  v-for="(btn, ind) in item.btns"
                  :key="ind"
                  v-show="btn.title"
                  :class="btn.type"
                  v-html="$util.rex.getHtml(btn[`${nowL}title`] || btn.title)"
                  @click="$util.route.go(btn.go)"
                ></div>
              </div>
            </div>
            <img class="img" :src="item.img" :alt="item.alt" />
          </div>
        </div>
        <div
          v-else-if="showPhone"
          class="item-box"
          :class="`edit_${Xindex}_item_${index}`"
          :data-e="item.go"
          @click="$util.route.go(item.go)"
        >
          <img class="bg-img" :src="item.phoneImg" />
        </div>
        <div
          v-else-if="item.type == 1"
          class="item-box"
          :class="`edit_${Xindex}_item_${index}`"
          @click="clickItem('item', index, item.go)"
        >
          <img class="bg-img" :src="item.bgImg" :alt="item.bgAlt" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'self-swiper',
  props: {
    configs: {
      default() {
        return {
          items: [],
        };
      },
    },
    Xindex: {
      default() {
        return '';
      },
    },
    hasEdit: {
      default() {
        return false;
      },
    },
    lang: {
      default() {
        return '';
      },
    },
  },
  watch: {
    lang() {
      this.initLang();
    },
  },
  data() {
    return {
      showOffer: false,
      showPhone: false,
      isEdit: false,
      nowL: '',
    };
  },
  created() {
    this.initLang();
    this.isEdit = this.$store.getters.getIsEdit;
    this.$listen.listen('swiper', (e) => {
      if (e < 1000) this.showPhone = true;
      else this.showPhone = false;

      let tabColor = document.querySelector('.swiper-box').dataset.tabColor;
      console.log(tabColor);
      if (!tabColor) tabColor = '#666666';
      if (document.querySelector('.header-box'))
        document.querySelector(
          '.header-box'
        ).style.cssText = `--menuColor:${tabColor}`;
    });
  },
  destroyed() {
    this.$listen.removeListen('swiper');
  },
  methods: {
    initLang() {
      let now_language = this.$storage.get('now_language');
      if (now_language === 'HK') now_language = '';
      this.nowL = now_language;
    },
    clickItem(e, index, go) {
      if (this.isEdit) this.$emit('clickItem', e, index);
      else {
        this.$util.route.go(go);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-box {
  .swiper {
    & /deep/ .el-carousel__container {
      height: 41vw;
    }
    & /deep/ .el-carousel__button {
      height: 8px;
      border-radius: 8px;
    }
    .item-box {
      position: relative;
      height: 100%;
      .bg-img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        left: 0;
        top: 0;
        object-fit: cover;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      .center-body {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: calc(100% - 720px);
        height: calc(100% - 168px);
        .text-body {
          margin-right: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            font-size: 44px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .desc {
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
            margin-top: 14px;
          }
          .btns {
            margin-top: 120px;
            display: flex;
            .btn {
              padding: 10px 24px;
              border-radius: 5px;
              margin-left: 20px;
              font-size: 16px;
              font-weight: 400;
              cursor: pointer;
              &:nth-child(1) {
                margin-left: 0;
              }
              &.default {
                color: #3068ed;
                background-color: #fff;
              }
              &.plain {
                color: #fff;
                border: 1px solid #fff;
              }
            }
          }
        }
        .img {
          object-fit: contain;
          height: 100%;
          max-width: 40%;
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .swiper-box {
    .text-body {
      .desc {
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .px-swiper-box {
    .swiper {
      & /deep/ .el-carousel__container {
        height: 100vw;
      }
      & /deep/ .el-carousel__indicators {
        font-size: 0;
        .el-carousel__indicator {
          padding: 6px 4px !important;
          .el-carousel__button {
            height: 6px;
          }
        }
      }
    }
  }
}
</style>
